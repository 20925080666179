<div *ngIf="isModalShown" [config]="{ show: true, keyboard: false, ignoreBackdropClick: true }" (onHidden)="onHidden()" bsModal #modal="bs-modal" class="modal fade">
    <div class="modal-dialog" [class]="modalDialogClass">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-start">{{ headerText }}</h4>
            </div>
            <div class="modal-body" [class.scroll]="isScrollbarShown">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="isDoneShown" class="btn action-button" (click)="onDone()" [disabled]="isDoneDisabled">
                    {{ doneText }}
                </button>
                <button type="button" *ngIf="isCancelShown" class="btn action-button" (click)="onCancel()">{{ cancelText }}</button>
            </div>
        </div>
        <ngx-loading [show]="isLoading" [config]="spinnerStyle"></ngx-loading>
    </div>
</div>
