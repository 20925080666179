import { Component, OnInit, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'
import { SelectItem } from 'primeng/api/selectitem'

@Component({
    selector: 'app-multi-select-input',
    templateUrl: './multi-select-input.component.html',
    styleUrls: ['./multi-select-input.component.css'],
})
export class MultiSelectInputComponent extends BaseInputComponent implements OnInit {
    @Input() options: SelectItem[]
    @Input() searchFilter = false
    @Input() isReadOnly: boolean = false
    readOnlyData: string

    constructor() {
        super()
    }

    ngOnInit() {
        if (this.isReadOnly) {
            const data: string[] = []
            this.control.value.forEach(element => {
                const option = this.options.find(i => i.value == element)
                if (option !== undefined) {
                    data.push(option.label!)
                }
            })
            this.readOnlyData = data.join(', ')
        }
    }
}
