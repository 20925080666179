<div class="mb-3 grouped-multi-select-input">
    <label *ngIf="label != null" for="{{ id }}">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired">*</span>
    <ng-select
        *ngIf="!isReadOnly; else readOnlyView"
        [items]="items"
        [multiple]="true"
        bindLabel="label"
        groupBy="group"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        bindValue="value"
        [formControl]="control"
        placeholder="Select secondary categories"
    >
    </ng-select>
    <ng-template #readOnlyView>
        <input readonly type="text" class="form-control" [value]="readOnlyData" />
    </ng-template>
    <span class="help-block" *ngIf="control.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
