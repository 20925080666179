<div class="mb-3">
    <div *ngIf="isReadOnly">
        <p class="form-label">{{ label }}</p>
        <div>{{ readOnlyData }}</div>
    </div>
    <div *ngIf="!isReadOnly">
        <label *ngIf="label != null">{{ label }}</label>
        <span class="required-indicator" *ngIf="showRequired">*</span>
        <div class="ui-fluid">
            <p-multiSelect defaultLabel="Not Selected" [options]="options" [filter]="searchFilter" [formControl]="control" [resetFilterOnHide]="searchFilter"></p-multiSelect>
        </div>
        <span class="help-block" *ngIf="control.invalid">
            <span class="required-indicator">
                {{ getErrorMessage() }}
            </span>
        </span>
    </div>
</div>
