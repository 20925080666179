import { Component, Self, Optional, OnInit, ViewEncapsulation, Input } from '@angular/core'
import { ControlValueAccessor, Validator, AbstractControl, NgControl } from '@angular/forms'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-date-entry-input',
    templateUrl: './date-entry-input.component.html',
    styleUrls: ['./date-entry-input.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateEntryInputComponent extends BaseInputComponent implements ControlValueAccessor, Validator, OnInit {
    isDisabled = false
    @Input() isReadOnly = false

    dates: { label: string; value: number }[]
    months: { label: string; value: number }[]

    yearText: string | null
    month: number | null
    date: number | null

    onChangeFunction: any
    onTouchedFunction: any

    constructor(@Self() @Optional() public controlDir: NgControl) {
        super()
        if (controlDir) {
            this.controlDir.valueAccessor = this
        }
    }

    ngOnInit() {
        this.initializeDates()
        this.setValidators()
    }

    validate(c: AbstractControl): { [key: string]: any } | null {
        const currentDateValue = c.value
        if (currentDateValue != null && !this.isADate(currentDateValue)) {
            return { invalidDate: 'Invalid date format' }
        }
        return null
    }
    registerOnValidatorChange?(fn: () => void): void {}

    writeValue(obj: Date): void {
        if (obj) {
            this.date = obj.getDate()
            this.month = obj.getMonth() + 1
            this.yearText = obj.getFullYear().toString()
        } else {
            this.date = null
            this.month = null
            this.yearText = null
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeFunction = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFunction = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled
    }

    override get showRequired(): boolean {
        if (this.controlDir != null && this.controlDir.control != null) {
            const control = this.controlDir.control
            if (control.validator != null) {
                const validator = control.validator({} as AbstractControl)
                if (validator && validator.required) {
                    return true
                }
            }
        }
        return false
    }

    override getErrorMessage() {
        if (this.controlDir != null) {
            const errors = this.controlDir.errors
            if (errors == null) {
                return null
            }
            const errorKeys = Object.keys(errors)
            if (errorKeys.length > 0) {
                // Get the first error and display error message
                const errorKey = errorKeys[0]
                const error = errors[errorKey]
                if (typeof error === 'string') {
                    // If error has a string value, display that as a message
                    return error
                } else {
                    // handle known errors
                    switch (errorKey) {
                        case 'required':
                            return 'This field is required'
                        default:
                            return 'This value is invalid'
                    }
                }
            }
        }
        return null
    }

    viewChanged() {
        this.callOnChangedAndOnTouched(this.generateDate(this.date!, this.month!, this.yearText!)!)
    }

    private generateDate(date: number, month: number, year: string): Date | null {
        const yearNumber = +year
        if (date != null && month != null && yearNumber != null && yearNumber != 0 && !isNaN(yearNumber)) {
            const newDate = new Date(yearNumber, month - 1, date)
            return newDate
        } else {
            if (date != null || month != null || (yearNumber != null && yearNumber != 0 && !isNaN(yearNumber))) {
                return new Date('asfsadfsa') // Return invalid date;
            }
            return null
        }
    }

    private setValidators() {
        const control = this.controlDir.control!
        let validators = control.validator ? [control.validator, this.validate.bind(this)] : this.validate.bind(this)
        control.setValidators(validators)
        control.updateValueAndValidity({ emitEvent: false })
    }

    private callOnChangedAndOnTouched(value: Date) {
        if (this.onChangeFunction) {
            this.onChangeFunction(value)
        }
        if (this.onTouchedFunction) {
            this.onTouchedFunction()
        }
    }

    private isADate(obj: Date): boolean {
        if (Object.prototype.toString.call(obj) === '[object Date]') {
            // it is a date
            if (isNaN(obj.getTime())) {
                // d.valueOf() could also work
                // date is not valid
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    private initializeDates() {
        this.dates = []
        this.months = []
        for (let i = 1; i <= 31; i++) {
            this.dates.push({ label: i.toString(), value: i })
        }
        for (let i = 1; i <= 12; i++) {
            this.months.push({ label: i.toString(), value: i })
        }
    }
}
