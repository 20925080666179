import { Component, OnInit, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.css'],
})
export class TextInputComponent extends BaseInputComponent implements OnInit {
    @Input() maxLength: number
    @Input() isReadOnly = false
    @Input() showCharsUsed = true
    @Input() placeholder = ''
    @Input() matchPattern: string
    @Input() helperText = ''

    constructor() {
        super()
    }

    ngOnInit() {}
}
