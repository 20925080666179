import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.css'],
})
export class WarningComponent {
    description: string
    headerText: string
    doneText: string

    constructor(private dialogRef: MatDialogRef<WarningComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.description || ''
        this.headerText = data.headerText || ''
        this.doneText = data.doneText || 'OK'
    }

    onDone() {
        this.dialogRef.close()
    }
}
