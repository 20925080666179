<nav class="navbar nav-header container-fluid tab-bar">
    <div class="nav-menu">
        <ul class="d-flex flex-row text-heading">
            <li *ngFor="let t of items" routerLinkActive="active" class="me-5">
                <a [routerLink]="t.routerLink" class="align-center manage-lots-selector">
                    <div>
                        <span [ngClass]="t.logoClass">
                            <span *ngIf="t.hasBadge && t.badgeNumber > 0" class="badge rounded-pill start-65">{{ t.badgeNumber }}</span>
                        </span>
                    </div>
                    {{ t.title }}
                </a>
            </li>
        </ul>
    </div>
</nav>
