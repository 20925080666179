import { Component, OnInit, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'
import { SelectItem } from 'primeng/api/selectitem'

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.css'],
})
export class SelectInputComponent extends BaseInputComponent implements OnInit {
    @Input() options: SelectItem[]
    @Input() searchFilter = false
    @Input() removable = false
    @Input() isReadOnly: boolean = false
    readOnlyData?: string = ''

    constructor() {
        super()
    }

    ngOnInit() {
        if (this.isReadOnly) {
            const option = this.options.find(i => i.value == this.control.value)
            if (option !== undefined) {
                this.readOnlyData = option.label
            }
        }
    }
}
