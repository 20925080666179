<div class="mb-3">
    <label *ngIf="label != null" for="{{ id }}">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired"> *</span>
    <input type="text" [typeahead]="list" [typeaheadMinLength]="0" id="{{ id }}" class="form-control" [formControl]="control" />
    <span class="help-block" *ngIf="control.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
