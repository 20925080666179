import { Input, Directive } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Directive()
export abstract class BaseInputComponent {
    @Input() id: string
    @Input() label: string
    @Input() control: AbstractControl

    get showRequired(): boolean {
        if (this.control != null && this.control.validator) {
            const validator = this.control.validator({} as AbstractControl)
            if (validator && validator.required) {
                return true
            }
        }
        return false
    }

    getErrorMessage() {
        if (this.control != null) {
            const errors = this.control.errors
            if (errors == null) {
                return null
            }
            const errorKeys = Object.keys(errors)
            if (errorKeys.length > 0) {
                // Get the first error and display error message
                const errorKey = errorKeys[0]
                const error = errors[errorKey]
                if (typeof error === 'string') {
                    // If error has a string value, display that as a message
                    return error
                } else {
                    // handle known errors
                    switch (errorKey) {
                        case 'required': {
                            return 'This field is required'
                        }
                        case 'exists': {
                            return 'Value exists already'
                        }
                        default:
                            return 'This value is invalid'
                    }
                }
            }
        }
        return null
    }
}
