import { Component, OnInit, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-date-time-input',
    templateUrl: './date-time-input.component.html',
    styleUrls: ['./date-time-input.component.css'],
})
export class DateTimeInputComponent extends BaseInputComponent implements OnInit {
    @Input() minimumDate: Date
    @Input() maximumDate: Date
    @Input() readonlyInput = true
    @Input() isReadOnly: boolean = false
    @Input() selectionMode = 'single'
    @Input() showTime = true
    @Input() showButtonBar = false
    readOnlyData

    constructor() {
        super()
    }

    ngOnInit() {
        if (this.isReadOnly) {
            const value = <Date>this.control.value
            if (value != null) {
                this.readOnlyData = value.toLocaleString('en-NZ')
            }
        }
    }
}
