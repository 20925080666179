import { Pipe, PipeTransform } from '@angular/core'

import { FilterCriteria, FilterCriterion } from 'app/shared/pipes/criteria-filter/filter-criterion.model'
import { CriterionComparator } from 'app/shared/pipes/criteria-filter/criterion-comparator.enum'

/**
 * Pipe for filtering data with a complex set of criteria
 */
@Pipe({
    name: 'criteriaFilter',
})
export class CriteriaFilterPipe implements PipeTransform {
    transform(value: any[], args: FilterCriteria) {
        if (this.isDataAvailableAndHasAnyFilterCriteria(value, args)) {
            const criteria = this.filterEmptyCriteria(args) // Remove empty criteria
            if (criteria.length <= 0) {
                return value
            } else {
                const filteredValues = value.filter(v => this.filterDataItemThroughCriteria(v, criteria))
                return filteredValues
            }
        } else {
            return value
        }
    }

    /**
     * Checks whether there is any data or if there is any filter criteria defined
     * @param data
     * @param filterCriteria
     */
    private isDataAvailableAndHasAnyFilterCriteria(data: any[], filterCriteria: FilterCriteria): boolean {
        return data && filterCriteria && data.length > 0 && filterCriteria.length > 0
    }

    /**
     * Removes any criterion that does not have a value on the filter criteria
     * @param filterCriteria
     */
    private filterEmptyCriteria(filterCriteria: FilterCriteria): FilterCriteria {
        return filterCriteria.filter(fc => fc.value != null && fc.value !== '')
    }

    /**
     * Runs a single data row against the criteria and sees if it passes all of them
     * If it does this function returns true otherwise returns false
     * @param value
     * @param filterCriteria
     */
    private filterDataItemThroughCriteria(value: any, filterCriteria: FilterCriteria): boolean {
        let criteriaPassed = true // By default, row passes a criteria
        // Loop through each criteria and if fails one, then the row is not included, therefore return false
        for (let i = 0; i < filterCriteria.length; i++) {
            const currentCriterion = filterCriteria[i]
            criteriaPassed = criteriaPassed && this.checkIfPassedCriterion(value, currentCriterion)
        }
        return criteriaPassed
    }

    private checkIfPassedCriterion(value: any, filterCriterion: FilterCriterion): boolean {
        let criterionPassed = false
        // A single criterion can look up multiple fields in the row, need to loop through them
        // At least one field needs to pass the criterion in order for the row to be included
        for (let i = 0; i < filterCriterion.forFields.length; i++) {
            const currentField = filterCriterion.forFields[i]
            const filterValue = filterCriterion.value
            let currentValue = value[currentField]
            // If dataField is supplied, need to get the value on one of the properties of the row
            if (currentValue != null && filterCriterion.dataField) {
                currentValue = value[currentField][filterCriterion.dataField]
            }
            if (currentValue == null) {
                continue
            } // If no value, skip field;

            if (filterCriterion.comparator === CriterionComparator.GreaterThan) {
                criterionPassed = criterionPassed || this.compareGreaterThan(currentValue, filterValue)
            } else if (filterCriterion.comparator === CriterionComparator.LessThan) {
                criterionPassed = criterionPassed || this.compareLessThan(currentValue, filterValue)
            } else if (filterCriterion.comparator === CriterionComparator.GreaterThanOrEqual) {
                criterionPassed = criterionPassed || this.compareGreaterThanOrEqual(currentValue, filterValue)
            } else if (filterCriterion.comparator === CriterionComparator.LessThanOrEqual) {
                criterionPassed = criterionPassed || this.compareLessThanOrEqual(currentValue, filterValue)
            } else if (filterCriterion.comparator === CriterionComparator.Contains) {
                criterionPassed = criterionPassed || this.compareContains(currentValue, filterValue)
            } else if (filterCriterion.comparator === CriterionComparator.NumericEquals) {
                criterionPassed = criterionPassed || this.compareNumericEquals(currentValue, filterValue)
            } else {
                criterionPassed = criterionPassed || this.compareDefault(currentValue, filterValue)
            }

            if (criterionPassed) {
                break
            } // If criterion already passed for a field, no need to check for other fields
        }
        return criterionPassed
    }

    /**
     * compare string contains lower case
     * @param value
     * @param filterValue
     */
    private compareDefault(value: any, filterValue: any) {
        const valueLowerCase = value.toString().toLowerCase()
        const filterValueLowerCase = filterValue.toString().toLowerCase()
        return valueLowerCase.indexOf(filterValueLowerCase) !== -1
    }

    /**
     * compare greater than
     * @param value
     * @param filterValue
     */
    private compareGreaterThan(value: any, filterValue: any): boolean {
        return value > filterValue
    }

    /**
     *  compare less than
     * @param value
     * @param filterValue
     */
    private compareLessThan(value: any, filterValue: any): boolean {
        return value < filterValue
    }

    /**
     * compare greater than or equal
     * @param value
     * @param filterValue
     */
    private compareGreaterThanOrEqual(value: any, filterValue: any): boolean {
        return value >= filterValue
    }

    /**
     * compare less than or equal
     * @param value
     * @param filterValue
     */
    private compareLessThanOrEqual(value: any, filterValue: any): boolean {
        return value <= filterValue
    }

    /**
     * compare contains
     * @param value
     * @param filterValue
     */
    private compareContains(value: any[], filterValue: any): boolean {
        if (Array.isArray(filterValue)) {
            if (filterValue != null && filterValue.length === 0) {
                return true
            }
            for (let i = 0; i < filterValue.length; i++) {
                if (value.indexOf(filterValue[i]) !== -1) {
                    return true
                }
            }
            return false
        } else {
            return value.indexOf(filterValue) !== -1
        }
    }

    /**
     * Compare numeric values if equal
     * @param value
     * @param filterValue
     */
    private compareNumericEquals(value: any, filterValue: any): boolean {
        if (Array.isArray(filterValue)) {
            if (filterValue != null && filterValue.length === 0) {
                return true
            }
            for (let i = 0; i < filterValue.length; i++) {
                if (+filterValue[i] === +value) {
                    return true
                }
            }
            return false
        } else {
            const numericValue = +value
            const numericFilterVlaue = +filterValue
            return numericValue === numericFilterVlaue
        }
    }
}
