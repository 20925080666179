import { Component, OnInit, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-text-area-input',
    templateUrl: './text-area-input.component.html',
    styleUrls: ['./text-area-input.component.css'],
})
export class TextAreaInputComponent extends BaseInputComponent implements OnInit {
    @Input() maxLength: string
    @Input() rows = 5
    @Input() isReadOnly = false

    constructor() {
        super()
    }

    ngOnInit() {}
}
