import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'sortBy',
})
export class SortPipe implements PipeTransform {
    transform(value: any, propName: string): any {
        if (value !== undefined) {
            return value.sort((a, b) => {
                if (a[propName] > b[propName]) {
                    return 1
                } else {
                    return -1
                }
            })
        }
    }
}
