import { Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
    selector: 'modal-content',
    templateUrl: './mediapopup.component.html',
})
export class MediaPopupComponent {
    isVideo: boolean
    source: any
    imageAlt: string
    constructor(public bsModalRef: BsModalRef) {}
}
