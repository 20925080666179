<div class="modal-content">
    <div class="modal-body">
        <button (click)="bsModalRef.hide()" type="button" class="close"><span>&times;</span><span class="sr-only">Close</span></button>
        <div *ngIf="isVideo; else image_content">
            <video width="640" height="480" controls autoplay style="margin: 0 auto; width: 100%">
                <source src="{{ source }}" type="video/mp4" />
                <source src="{{ source }}" type="video/ogg" />
            </video>
        </div>
        <ng-template #image_content>
            <img src="{{ source }}" alt="{{ imageAlt }}" style="width: 100%" />
        </ng-template>
    </div>
    <div class="modal-footer"></div>
</div>
