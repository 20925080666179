<div class="mb-3">
    <label *ngIf="label != null" for="{{ id }}">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired">*</span>
    <input
        type="text"
        id="{{ id }}"
        class="form-control"
        [formControl]="control"
        (keypress)="onInputChange($event)"
        (paste)="onPaste($event)"
        [readonly]="isReadOnly"
        maxlength="{{ maxLength }}"
        #inputElement
    />
    <span class="help-block" *ngIf="control.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
