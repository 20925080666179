import { AfterContentInit, Component, ContentChild, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Globals } from 'app/core/services/globals.service'
import { BaseDialogContent } from 'app/shared/components/base-dialog-content'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { Observable, Observer, Subscription } from 'rxjs'
import { finalize, publish } from 'rxjs/operators'
import { isObservable } from 'rxjs'

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
})
export class DialogComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('modal') modal: ModalDirective
    @ContentChild(BaseDialogContent) dialogContent: BaseDialogContent

    @Input() headerText: string
    @Input() doneText = 'Done'
    @Input() cancelText = 'Cancel'
    @Input() isDoneShown = true
    @Input() isCancelShown = true
    @Input() isScrollbarShown = false
    @Input() modalDialogClass = ''

    subscriptions: Subscription[] = []

    isModalShown = false
    isLoading = false
    isDoneDisabled = false
    spinnerStyle = Globals.sectionLoadingStyle

    private currentObserver: Observer<any>

    constructor() {}

    ngOnInit() {}

    ngAfterContentInit() {
        this.subscriptions.push(this.dialogContent.isLoading$.subscribe(isLoading => (this.isLoading = isLoading)))
        this.subscriptions.push(this.dialogContent.isDoneDisabled$.subscribe(isDoneDisabled => (this.isDoneDisabled = isDoneDisabled)))
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    open(arg?: any) {
        this.isModalShown = true
        const open$ = this.dialogContent.onOpen(arg)
        if (isObservable(open$)) {
            this.isLoading = true
            open$
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe({
                    next: v => {},
                    error: error => {},
                })
        }

        // Initialise observable
        const obs$ = Observable.create((observer: Observer<any>) => {
            this.currentObserver = observer
        }).pipe(publish())
        obs$.connect()
        return obs$
    }

    onHidden() {
        this.isModalShown = false
    }

    onDone() {
        const obs$ = this.dialogContent.onDone()
        if (isObservable(obs$)) {
            obs$.subscribe({
                next: v => this.doneAndClose(v),
                error: error => {
                    this.doneAndClose(error, true)
                },
            })
        } else {
            // Check whether we have a result
            if (obs$ !== undefined) {
                // Check whether result is true
                if (obs$) {
                    // Do not hide modal as there are form errors
                    return
                }
            }
            // Hide modal
            this.modal.hide()
        }
    }

    onCancel() {
        this.dialogContent.onCancel()
        this.currentObserver.complete()
        this.modal.hide()
    }

    private doneAndClose(returnValue?: any, error?: boolean) {
        if (error) {
            this.currentObserver.error(returnValue)
        } else {
            this.currentObserver.next(returnValue)
            this.currentObserver.complete()
        }
        this.modal.hide()
    }
}
