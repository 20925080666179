<!-- <div class="modal-dialog"> -->
<!-- <div class="modal-content"> -->
<div class="modal-header">
    <h4 class="modal-title float-start">{{ headerText }}</h4>
</div>
<div class="modal-body">
    <p>
        {{ description }}
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn action-button" (click)="onDone()">
        {{ doneText }}
    </button>
</div>
<!-- </div>
</div> -->
