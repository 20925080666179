<div class="mb-3">
    <div *ngIf="isReadOnly">
        <label *ngIf="label != null">{{ label }}</label>
        <span class="required-indicator" *ngIf="showRequired">*</span>
        <input readonly="true" type="text" id="{{ id }}" class="form-control" [value]="readOnlyData" />
    </div>
    <div *ngIf="!isReadOnly">
        <label *ngIf="label != null">{{ label }}</label>
        <span class="required-indicator" *ngIf="showRequired">*</span>
        <div class="ui-fluid">
            <p-dropdown
                placeholder="Not Selected"
                [options]="options"
                [showClear]="removable"
                autoWidth="false"
                [filter]="searchFilter"
                [formControl]="control"
                [resetFilterOnHide]="searchFilter"
            ></p-dropdown>
        </div>
        <span class="help-block" *ngIf="control.invalid">
            <span class="required-indicator">
                {{ getErrorMessage() }}
            </span>
        </span>
    </div>
</div>
