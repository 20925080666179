import { Observable, Subject } from 'rxjs'

export abstract class BaseDialogContent {
    private isLoadingSubject = new Subject<boolean>()
    isLoading$ = this.isLoadingSubject.asObservable()

    private isDoneDisabledSubject = new Subject<boolean>()
    isDoneDisabled$ = this.isDoneDisabledSubject.asObservable()

    constructor() {}

    abstract onDone(): Observable<any> | void | boolean
    abstract onCancel(): void
    abstract onOpen(arg: any): Observable<any> | void

    protected setIsLoading(isLoading: boolean) {
        this.isLoadingSubject.next(isLoading)
    }

    protected setDoneDisabled(isDoneDisabled: boolean) {
        this.isDoneDisabledSubject.next(isDoneDisabled)
    }
}
