<div class="mb-3 date-entry-input">
    <label *ngIf="label != null">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired">*</span>
    <div class="row" *ngIf="isReadOnly">
        <div class="col-lg-4">
            Day
            <input [value]="date" class="form-control" readonly="true" />
        </div>
        <div class="col-lg-4">
            Month
            <input [value]="month" class="form-control" readonly="true" />
        </div>
        <div class="col-lg-4">
            Year
            <input [value]="yearText" class="form-control" readonly="true" />
        </div>
    </div>
    <table *ngIf="!isReadOnly" class="date-entry-table">
        <tr>
            <td class="td-day">
                <p-dropdown [options]="dates" [(ngModel)]="date" placeholder="date" autoWidth="false" (ngModelChange)="viewChanged()" [showClear]="false"></p-dropdown>
            </td>
            <td class="td-month">
                <p-dropdown [options]="months" [(ngModel)]="month" placeholder="month" autoWidth="false" (ngModelChange)="viewChanged()" [showClear]="false"></p-dropdown>
            </td>
            <td class="td-year">
                <p-inputMask mask="9999" [(ngModel)]="yearText" placeholder="year" slotChar="yyyy" (ngModelChange)="viewChanged()"></p-inputMask>
            </td>
        </tr>
    </table>
    <span class="help-block" *ngIf="controlDir.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
