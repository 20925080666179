import { Component, Input } from '@angular/core'

import { HeaderItem } from 'app/shared/components/tab-header/header-item.model'

@Component({
    selector: 'app-tab-header',
    templateUrl: './tab-header.component.html',
    styleUrls: ['./tab-header.component.css'],
})
export class TabHeaderComponent {
    @Input() items: HeaderItem[] = []
}
