import { Directive, OnInit, Input, HostBinding, HostListener, OnDestroy } from '@angular/core'

@Directive({
    selector: '[appProcessedImage]',
})
export class ProcessedImageDirective implements OnInit, OnDestroy {
    private processingUrl = 'assets/images/processing.gif'
    private currentTimeout: any

    @Input() src: string
    @HostBinding('attr.src') srcBinding: string

    @HostListener('load', ['$event.target'])
    onLoad(target) {}

    @HostListener('error', ['$event.target'])
    onError(target) {
        this.srcBinding = this.processingUrl
        this.currentTimeout = setTimeout(() => {
            this.srcBinding = this.src
        }, 5000)
    }

    ngOnInit() {
        this.srcBinding = this.src
    }

    ngOnDestroy() {
        if (this.currentTimeout != null) {
            clearTimeout(this.currentTimeout)
        }
    }
}
