import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-grouped-multi-select-input',
    templateUrl: './grouped-multi-select-input.component.html',
    styleUrls: ['./grouped-multi-select-input.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class GroupedMultiSelectComponent extends BaseInputComponent implements OnInit {
    @Input() items: { label: string; value: any; group?: string }[]
    @Input() isReadOnly: boolean

    readOnlyData: string

    ngOnInit() {
        if (this.isReadOnly) {
            const data: string[] = []
            this.control.value.forEach(element => {
                const option = this.items.find(i => i.value === element)
                if (option != null) {
                    data.push(option.label!)
                }
            })
            this.readOnlyData = data.join(', ')
        }
    }
}
