<div class="mb-3">
    <label *ngIf="label != null" for="{{ id }}">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired">*</span>
    <textarea [readonly]="isReadOnly" [attr.rows]="rows" class="form-control" [formControl]="control" maxlength="{{ maxLength }}" #inputControl></textarea>
    <p class="text-end">
        <small class="form-text text-muted">
            <code>{{ inputControl.value.length }}</code> of {{ maxLength }} characters used
        </small>
    </p>
    <span class="help-block" *ngIf="control.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
