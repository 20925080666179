<div class="mb-3">
    <label *ngIf="label != null" for="{{ id }}">{{ label }}</label>
    <span class="required-indicator" *ngIf="showRequired">*</span>
    <input
        [readonly]="isReadOnly"
        placeholder="{{ placeholder }}"
        [pattern]="matchPattern"
        type="text"
        id="{{ id }}"
        class="form-control"
        maxlength="{{ maxLength }}"
        [formControl]="control"
        #inputControl
    />
    <p class="text-end" *ngIf="showCharsUsed">
        <small class="form-text text-muted">
            <code>{{ inputControl.value.length }}</code> of {{ maxLength }} characters used
        </small>
    </p>
    <p class="text-end" *ngIf="helperText.length > 0">
        <small class="form-text text-muted">
            {{ helperText }}
        </small>
    </p>
    <span class="help-block" *ngIf="control.invalid">
        <span class="required-indicator">
            {{ getErrorMessage() }}
        </span>
    </span>
</div>
