import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'humanReadable',
})
export class HumanReadablePipe implements PipeTransform {
    transform(value: string): string {
        if (value == null) {
            return ''
        }
        if (typeof value !== 'string') {
            return ''
        }
        if (!/\S/.test(value)) {
            return ''
        }

        return value.charAt(0).toUpperCase() + value.substr(1).replace(/[A-Z]/g, ' $&')
    }
}
