import { Component, ViewChild, ElementRef, Input } from '@angular/core'

import { BaseInputComponent } from 'app/shared/components/forms/base-input-component'

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.css'],
})
export class NumberInputComponent extends BaseInputComponent {
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef
    @Input() integersOnly = false
    @Input() allowNegatives = false
    @Input() isReadOnly: boolean = false
    @Input() maxLength: number
    @Input() allowLeadingZeros: boolean = false

    constructor() {
        super()
    }
    ngOnInit() {}

    onPaste(event) {
        // Prevent pasting
        event.preventDefault()
    }

    onInputChange(event) {
        if (event instanceof KeyboardEvent) {
            const inputNativeElement = this.inputElement.nativeElement
            const value = inputNativeElement.value
            const ch = String.fromCharCode(event.which)
            if (!this.allowNegatives && ch === '-') {
                // If don't allow negatives, don't allow dash input
                event.preventDefault()
            }
            if (this.allowNegatives && ch === '-' && value !== '') {
                // If allow negatives, only allow dash input if there is no value yet (first character)
                event.preventDefault()
            }
            if (this.integersOnly && ch === '.') {
                // If integers only and period was input, don't allow;
                event.preventDefault()
            }
            if (!this.integersOnly && ch === '.' && value.indexOf('.') !== -1) {
                event.preventDefault()
            }
            if (/[0-9]/.test(ch)) {
                if (value.length > 1) {
                    if (value[0] === '-' && value[1] === '0' && value.indexOf('.') === -1) {
                        event.preventDefault()
                    }
                } else if (value.length > 0) {
                    if (!this.allowLeadingZeros) {
                        if (value[0] === '0') {
                            event.preventDefault()
                        }
                    }
                }
            }
            if (!/[0-9.-]/.test(ch)) {
                event.preventDefault()
            }
        }
    }
}
