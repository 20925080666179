import { Component, OnInit, Input } from '@angular/core'
import { BaseInputComponent } from '../base-input-component'

@Component({
    selector: 'app-autocomplete-input',
    templateUrl: './autocomplete-input.component.html',
    styleUrls: ['./autocomplete-input.component.css'],
})
export class AutocompleteInputComponent extends BaseInputComponent implements OnInit {
    @Input()
    list: string[]

    constructor() {
        super()
    }

    ngOnInit() {}
}
